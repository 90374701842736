import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Button, Row, Col, Alert } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import logoig from '../assets/img/nasta/logoig.svg'
import burung from '../assets/img/nasta/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar, pw } from '../params'
import { Timer } from '../components/timer'
import post from '../params/post'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/nasta/covid.png'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

let cmain = '#69785B'
let black = 'rgb(38,38,38)'

let id_temp = 'zhafirah-wahyu'
let id = 'nadya-faisal'
let inisial_co = 'Faisal'
let inisial_ce = 'Nadya'
let lengkap_co = 'Faisal Tamimi, S.T '
let lengkap_ce = 'Nadya Maharani, S.T '
let bapak_co = 'Bpk. Humam Hasani'
let ibu_co = 'Ibu Maimunatun'
let bapak_ce = "Bpk. Edy Santoso "
let ibu_ce = "Ibu Handayani Sri Suwarni"
let ig_co = "faisaltamimi_"
let ig_ce = "nadyamahaarani"

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "1.jpg")
let waktunikah = "02/20/2021"

let modal = pw(id, "modal.jpg")
let openlogo = pw(id, "logo.png")

let gmaps = "https://goo.gl/maps/Ph21miFeN4v2kMXDA"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=NTJ0NnJrbmdobzloaDhpdGIwam8xYWh0NnMgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.787540738533!2d110.01359591477764!3d-7.705933794445224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7aebbea431bb13%3A0x8f75a7c90d65b5af!2sKPPN%20Purworejo!5e0!3m2!1sid!2sid!4v1609168078333!5m2!1sid!2sid"



export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 0
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });


    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, showrek } = this.state
        let slider = []
        let slide = [
            "ODPP0035.jpg",
"ODPP0039.jpg",
"ODPP0197.jpg",
"ODPP0199.jpg",
"ODPP0314.jpg",
"ODPP0494.jpg",
"ODPP0506.jpg",
"ODPP0659.jpg",
"ODPP0670.jpg"
        ]
        slide.map(v=>{
            slider.push(gambar(pw(id, v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1440&h=720'))
        })
           
        
        let query = this.useQuery().get('u');

        query = query ? cap(query) : ''
        let story = [
            "	https://i.ibb.co/T2qKtDH/2.jpg	",
            "	https://i.ibb.co/Nr6J8VK/3.jpg	",
            "	https://i.ibb.co/1TJCDxD/4.jpg	",
            "	https://i.ibb.co/6wzHBHw/5.jpg	",
            "	https://i.ibb.co/kJyJZz2/1.jpg	",
      
          ]
          let caption = [
            `Perkenalan singkat dengan cerita yang tak begitu berkelit, adalah awal kisah ini dimulai. Sangat mudah meyakinkan diri terhadap jodoh yang ditakdirkan Allah SWT.
            `,
            `Hati ini menemukan dermaga yang tepat untuk berlabuh, rumah yg nyaman untuk pulang dan pasangan yg cocok untuk menata masa depan. 
            `,
            `Dari semua yang pernah datang, mengajarkan cara menggenggam tangan. Sedang, dari semua yang memilih pergi, memberikan arti memiliki. Memilihnya bukan karena dia sempurna, namun perasaan saling melengkapi membuat ini jauh lebih baik. 
            `,
            `Entah dari mana datangnya, Tuhan mempertemukan kami dengan cara yang unik. Sebuah cerita yang akan kami ingat seumur hidup. Memang tak seindah drama korea, namun kami berharap menemukan surga di akhir cerita.
            `, `
            Agar cinta tak berhenti sebagai wacana, agar harapan tak kandas di tengah jalan, 
            Mungkin bisa dimulai dari cincin yang seharusnya melingkar d jari. Menyatukan dua keluarga dalam ikatan pernikahan,  adalah jalan yang kami pilih.
       `
          ]
        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undang.in/${id}`}
                />

                <div id='gold5' style={{
                   backgroundImage: `url(${gambar('https://image.freepik.com/free-vector/floral-pattern-gentle-pastel-colors_1191-346.jpg', 95, 'auto&blur=3&bright=20')})`,
                   backgroundSize: '100%',
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='relative' style={{
                            backgroundImage: `url('${modal}')`
                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-4'
                                        src={gambar(openlogo)} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>

                                        <p className="fs16 text-center cblack px-3">

                                        “And of His signs is that He created for you from your selves mates that you may find tranquility in them; and He placed between you affection and mercy. Indeed in that are signs for a people who give thought.”
                                            <br />
                                                <br />
                                            ( QS. Ar- Rum 21 )
                                        </p>
                                    </Item>
                                    <div className="embed-responsive embed-responsive-4by3 mb-3 p-3">
                                        <iframe className="embed-responsive-item"
                                            src="https://www.youtube.com/embed/_T-swLnw_L0"></iframe>
                                    </div>

                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>

                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: cmain }}>
                                            The Intimate Wedding of <br /> {inisial_ce} & {inisial_co}
                                        </p>
                                    </Item>
                                </Container>

                                <Container fluid style={{backgroundColor:cmain}}>
                                <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', color: cmain, fontFamily: "'Marck Script', cursive" }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_ce, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{ fontSize: '32px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putri dari :</b><br />
                                                        {bapak_ce}  <br />
                                                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={pw("asset","logoig-green.svg")} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={foto_co} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '32px',
                                                        fontFamily: "'Marck Script', cursive", color: cmain
                                                    }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>Putra dari:</b><br />
                                                        {bapak_co}
                                                        <br />
                                                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <img src={pw("asset","logoig-green.svg")} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: black }} >
                                    <Item>
                                        <p className="fs16">
                                            Yang Insyaallah akan dilaksanakan pada:
                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20">
                                            <b>
                                                SABTU <span className="fs36">20</span> FEBRUARI 2020
                      </b>
                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20 col-sm-4" style={{ color: cmain }}>
                                            <b>Akad Nikah</b><br />
                                            <span className="cblack fs16">
                                                
                                                08.00 WIB- Selesai
                                            
                                            </span>
                                          
                                        </p>
                                        
                                        <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                                        </div>
                                        <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: cmain }}>
                                            <b>Resepsi</b><br />

                                            <span className="cblack fs16">
                                               
                                            11.00-13.00 WIB
                                                    
                                               </span>


                                        </p>
                                    </Item>
                                    <Item>
                                    <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: cmain }}>
                                            
                                            <span className="cblack fs16">
                                                <b>Gedung KPPN Purworejo 
                                                    </b><br />
                                                    Jl.Jend. Urip Sumoharjo No.83, Purworejo - Jawa Tengah

                                               </span>


                                        </p>
                                    </Item>

                                    <Item>
                                        <p style={{ fontSize: '16px', fontStyle: 'italic' }}>Undangan Hanya untuk 2 orang</p>
                                    </Item>
                                    <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src={gmaps1} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                            </iframe></div>
                                        </div>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>

                                </Container>
                                <Timer cmain={cmain} waktunikah={waktunikah} />
                <Container className="py-5 px-md-5 col-md-8 px-3" 
                  style={{ color: 'white', fontFamily: 'Patrick Hand, cursive',backgroundColor:cmain }}
                >

                  <Row className="position-relative px-5 py-5" style={{ backgroundColor: 'rgba(255,255,255,0.1)' }}>
                    <h1 className="peri w-100 text-center">
                      Our Love Story
                  </h1>
                    <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', top: 0 }}>
                      <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="img-fluid w-100" />
                    </Col>
                    <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', top: 0 }}>
                      <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="mirror img-fluid w-100" />
                    </Col>
                    <Col md={2} xs={4} className="position-absolute" style={{ left: '-15px', bottom: 0 }}>
                      <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className="flip180-m img-fluid w-100" />
                    </Col>
                    <Col md={2} xs={4} className="position-absolute" style={{ right: '-15px', bottom: 0 }}>
                      <img src={gambar('https://i.ibb.co/NNpfXF0/ornament.png', 95)} className=" flip180  img-fluid w-100" />
                    </Col>
                    {story.map((v, i) => {
                      return (
                        <Item>
                          <Col xs={12} md={5} className={`${i % 2 ? 'order-md-2' : 'order-md-1'} text-center `}>
                            <img src={gambar(pw(id,slide[i]), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=500&h=500')} className="img-fluid w-100 p-3" />
                          </Col>
                          <Col xs={12} md={7} className={i % 2 ? 'order-md-1' : 'order-md-2'} >
                            <Row className="h-100">
                              <p className="w-100 text-center my-auto" style={{ fontSize: '18px' }}>
                                " {caption[i]} "
                            </p>
                            </Row>
                          </Col>
                        </Item>
                      )
                    })}
                  </Row>
                </Container>

            
                                <Container className="py-3">
                                    <Item>
                                        <Col xs={12} md={6}>
                                            <img src={pw("asset","covid-green.svg")} className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                </Container>


                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56' className="py-3 px-4" >
                    <div className='pt-3'>
                      <div data-aos={`fade-right`} data-aos-duration="2000">
                        <Item>
                          <div className='kotak col-12 position-relative' style={{ backgroundColor: 'white', overflow: 'hidden' }}>
                            <div className="position-absolute w-50" style={{
                              top: 0, left: 0, zIndex: 0, opacity: 0.5
                            }}>
                              <img src={gambar('https://i.ibb.co/hyV37Bg/Daun-daunan.png', 40)} className="w-100 img-fluid" />
                            </div>
                            <div className="position-absolute w-50" style={{
                              top: 0, right: 0, zIndex: 0, opacity: 0.5, transform: 'scaleX(-1)'
                            }}>
                              <img src={gambar('https://i.ibb.co/hyV37Bg/Daun-daunan.png', 40)} className="w-100 img-fluid" />
                            </div>
                            <Item>
                              <p className='text-center p-2 px-2 pt-5 amatic' style={{ zIndex: 1, color: 'black' }}>
                                <div style={{ fontWeight: 700 }} className="d-inline">50.000</div> years before the sky introduced to the sea, Allah wrote down your name next to me. No matter how long it takes, how far we go, how tough it seems, then finally we find each other (again).
                              </p>
                            </Item>
                          </div>
                        </Item>
                      </div>
                    </div>
                  </Container>
                

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={4} lg={2}>
                                                <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Send Your Wishes
                    </h1>
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />


                                                        <Item>
                                                            <div id="formradio">
                                                                <div class="custom_radio row justify-content-center">
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: true })
                                                                    }
                                                                    }>
                                                                        <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                        <label for="featured-1">Hadir</label>
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: false })
                                                                    }
                                                                    } className="pl-5">
                                                                        <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                        <label for="featured-2"

                                                                        >Tidak Hadir</label>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </Item>


                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                <Container fluid style={{ backgroundColor: cmain, color: 'white' }} className="py-3">
                                <Item>
                                <Button style={{backgroundColor:'#9D2A2D',borderColor:'#A2205C'}} onClick={()=>{
                                                        this.setState({showrek:true})
                                                    }}>
                                                        <Item>
                                                            <p className="px-2 mb-0" style={{fontSize:'14px'}}>
                                                            Send Gift <img src='https://www.flaticon.com/svg/static/icons/svg/837/837891.svg' style={{width:'25px',height:'25px',marginLeft:'10px'}}/>
                                                        
                                                            </p>
                                                            </Item>
                                                    </Button>
                                </Item>
                                    {
                                        showrek?(<>
                                            <Item>
                                    <p className="text-center mx-5 py-3" style={{fontSize:'16px'}}>
                                    Tanpa mengurangi rasa hormat, bagi anda yang ingin memberikan tanda kasih untuk pengantin, dapat melalui:
                                    </p>
                                    </Item>
                                    <Item>
                                        <div className="p-3 px-5" style={{backgroundColor:'#E3A2C0'}}>
                                            <Item>
                                            <p className="text-center" style={{fontSize:'16px'}}>
                                                <b>2350302649</b><br/>
                                                Bank BCA<br/>
                                                a.n. Nadya
                                            </p>
                                            </Item>
                                            <Item>
                                            <CopyToClipboard text="2350302649">
                                                    <div className="position-relative">
                                                    
                                                    <Button style={{backgroundColor:'#9D2A2D',borderColor:'#A2205C'}} onClick={()=>{
                                                        this.setState({copy:true})
                                                    }}>
                                                        <Item>
                                                            <p className="px-2 mb-0" style={{fontSize:'14px'}}>
                                                            {this.state.copy?'Berhasil di Copy ke Clipboard':'Copy Nomor Rekening'} <img src='https://www.flaticon.com/svg/static/icons/svg/926/926768.svg' style={{width:'25px',height:'25px',marginLeft:'10px'}}/>
                                                        
                                                            </p>
                                                            </Item>
                                                    </Button>
                                                    <Button onClick={()=>{
                                                        this.setState({copy:true})
                                                    }}
                                                    style={{backgroundColor:'#CC6262',borderColor:'#A2205C',position:'absolute',left:'-5px',top:'-5px'}}
                                                >
                                                        <Item>
                                                            <p className="px-2 mb-0" style={{fontSize:'14px'}}>
                                                            {this.state.copy?'Berhasil di Copy ke Clipboard':'Copy Nomor Rekening'} <img src='https://www.flaticon.com/svg/static/icons/svg/926/926768.svg' style={{width:'25px',height:'25px',marginLeft:'10px'}}/>
                                                            </p>
                                                            </Item>
                                                    </Button>
                                                   
                                                    </div>
                                                    </CopyToClipboard>
                                                </Item>
                                                <Item>
                                                <p className="text-center mx-5 py-3 w-100" style={{fontSize:'16px'}}>
                                                    <b>
                                                        Alamat pengiriman kado:
                                                    </b><br/>
                                                Komplek Hankam Cidodol No 19 Kebayoran Lama Jakarta Selatan
                                    </p>
                                                </Item>
                                            </div>
                                    </Item>
</>
                                        ):false
                                    }
                                    </Container>
                                

                                <Foot ig={pw("asset","logoig-green.svg")} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

